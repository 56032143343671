import React, { Component } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Flex, Box, Heading, Button } from 'rebass';
import Text from '../components/styles/Text';
import { plural } from '../utils/helpers';
import Container from '../components/styles/Container';
import Modal from 'react-modal';
import BodyText from '../components/styles/BodyText';
import Page from '../components/Page';
import { graphql, navigateTo } from 'gatsby';
import { navigate } from '@reach/router';
import Helmet from 'react-helmet';
import moment from 'moment';

const PostContainer = styled(Container)`
  @media only screen and (max-width: 600px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const HeadImage = styled(Img)`
  max-height: 60vh;
`;

export default class PostPage extends Component {
  back = e => {
    // stop going back if there is no history
    e.stopPropagation();
    if (window.history.length > 2) {
      window.history.back();
    } else navigateTo('/');
  };

  // componentDidMount() {
  //   fetchItem();
  // }

  render() {
    let stateFeed;
    if (this.props.location.state == null) {
      stateFeed = false;
    } else {
      stateFeed = true;
    }
    const { data } = this.props;
    const {
      title,
      featuredImage,
      body,
      bodyPreview,
      slug,
      publishDate,
    } = this.props.data.contentfulBlogPost;
    const readTime = body.childMarkdownRemark.timeToRead;
    if (!data) return null;

    const rawURL = featuredImage.sizes.src.substring(2);
    const splitURL = rawURL.split('?');
    const firstURL = splitURL[0];

    return (
      <div>
        {/* {bbutton} */}
        <Page>
          <Helmet>
            <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
            <meta name="description" content={bodyPreview} />
            <meta name="image" content={`https://${firstURL}`} />
            <meta
              property="og:title"
              content={`${title} | ${data.site.siteMetadata.title}`}
            />
            <meta property="og:image" content={`https://${firstURL}`} />
            <meta property="og:description" content={bodyPreview} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@modarri_cars" />
            <meta
              name="twitter:title"
              content={`${title} | ${data.site.siteMetadata.title}`}
            />
            <meta name="twitter:description" content={bodyPreview} />
            <meta name="twitter:image" content={`https://${firstURL}`} />
          </Helmet>
          {/* <PostBanner
            readTime={readTime}
            title={title}
            type="article"
            slug={slug}
            date={publishDate}
          /> */}

          <Container mx="9%" py="10vh" px={5}>
            <Flex flexWrap="wrap" mx="auto" pt="20px">
              <Box width={[1]} pt="40px">
                <Img fluid={featuredImage.sizes} />
              </Box>
              <Box width={1}>
                <Heading
                  as="h6"
                  fontSize={[4, 5, 6]}
                  mt="40px"
                  mb="20px"
                  color="black"
                >
                  {title}
                </Heading>
                <Heading color="rgb(120, 140, 150);" fontSize={3} py={3}>
                  {moment(publishDate).format('MMM D, YYYY')}
                </Heading>
                <Heading color="rgb(120, 140, 150);" fontSize={3}>
                  Read Time: {readTime} minutes
                </Heading>
              </Box>
            </Flex>
          </Container>
          <PostContainer px={5} mx="9%">
            <BodyText
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            />
          </PostContainer>
        </Page>
      </div>
    );
  }
}

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      slug
      bodyPreview
      id
      publishDate
      featuredImage {
        sizes(maxWidth: 800) {
          ...GatsbyContentfulSizes
        }
      }
    }
  }
`;
